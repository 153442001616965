<template>
    <div>
        <div class="print-detail d-print-block hidden-print-only" >
            <order-detail :order="singleOrder"></order-detail>
        </div>
        <div class="print-menu d-print-none">
            <div>
                <v-btn @click="printHandler" class="mr-2">Print Again</v-btn>
                <v-btn @click="windowClose">Close Window</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import OrderDetail from "@/components/OrderDetail";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "order-ID-print",
        components: {OrderDetail},
        computed: {
            ...mapGetters('orderStore', ['findOrder', 'singleOrder']),
            ...mapGetters('restaurantStore', ['id']),
            ...mapGetters({
                loggedIn: 'userStore/loggedIn'
            })
        },
        methods: {
            ...mapActions('orderStore', {
                    fetchOrder: 'fetchSingleOrder'
                }
            ),
            printHandler() {
                print()
            },
            windowClose() {
                window.close()
            }
        },
        watch: {
            loggedIn(val) {
                if(val) {
                    this.fetchOrder(this.$route.params.id).then(print)
                }
            }
        },
        created() {
            window.addEventListener('afterprint', () => {
                window.close()
            })
        }
    }
</script>

<style scoped lang="sass">
    .print-detail
        width: 100vw
        position: absolute
        top: 0
    .print-menu
        z-index: 999
        position: fixed
        width: 100vw
        height: 100vh
        top: 0
        left: 0
        background-color: rgba(0,0,0,0.5),
        display: flex
        align-items: center
        justify-content: center
</style>